import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyPolicyComponent } from './cms-pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './cms-pages/terms-conditions/terms-conditions.component';
import { HomeComponent } from './home/home.component';
import { DeeplinkComponent } from './deeplink/deeplink.component';

const routes: Routes = [  
  { path: '', component: HomeComponent},
  {path: 'rideapp/rider/:id', component:DeeplinkComponent},
  {path: 'rideapp/rider', component:DeeplinkComponent},
  {path: 'rideapp/driver/:id', component:DeeplinkComponent},
  {path: 'rideapp/driver', component:DeeplinkComponent},
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'term-conditions', component: TermsConditionsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
