import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deeplink',
  templateUrl: './deeplink.component.html',
  styleUrls: ['./deeplink.component.css']
})
export class DeeplinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    let UrlSegmentsArray = window.location.href.split('/')
    let segment = UrlSegmentsArray[UrlSegmentsArray.length-2]
    let lastSegment = UrlSegmentsArray[UrlSegmentsArray.length-1]
    console.log("SEF",segment)
    console.log("lastSegment",lastSegment)
    //return false
    console.log("IN FRONT END DEEPLINK")
    var OSName="Unknown OS";
        if (navigator.appVersion.indexOf("Win")!=-1) OSName="Windows";
        if (navigator.appVersion.indexOf("Mac")!=-1) OSName="MacOS";
        if (navigator.appVersion.indexOf("X11")!=-1) OSName="UNIX";
        if (navigator.appVersion.indexOf("Linux")!=-1) OSName="Linux";
        if (navigator.appVersion.indexOf("Android")!=-1) OSName="Android";
        // todo:Need to change url for app/play store
        if(OSName == "Android"){
            if(segment == 'rider' || lastSegment == 'rider'){
              window.location.replace('https://play.google.com/store/apps/details?id=com.ridetechnologies.rider');
            }else{
              window.location.replace('https://play.google.com/store/apps/details?id=com.ridetechnologies.driver');
            }
        }else if(OSName == "MacOS"){
            if(segment == 'rider' || lastSegment == 'rider'){
              window.location.replace('https://apps.apple.com/us/app/ride-get-a-ride/id1579331501');
            }else{
              window.location.replace('https://apps.apple.com/us/app/ride-driver/id1579680318');
            }
        }else{
            if(segment == 'rider' || lastSegment == 'rider'){
              window.location.replace('https://play.google.com/store/apps/details?id=com.ridetechnologies.rider');
            }else{
              window.location.replace('https://play.google.com/store/apps/details?id=com.ridetechnologies.driver');
            }
        }
  }

}