import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrivacyPolicyComponent } from './cms-pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './cms-pages/terms-conditions/terms-conditions.component';
import { HomeComponent } from './home/home.component';
import { DeeplinkComponent } from './deeplink/deeplink.component';

@NgModule({
  declarations: [
    AppComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    HomeComponent,
    DeeplinkComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
